<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title class="title">
      This is my blog <span *ngIf="this.isBrowser" class="isbrowser"> [Is Browser]</span><span *ngIf="!this.isBrowser" class="isbrowser"> [Is Server]</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-card *ngFor="let p of posts">
    <img src="{{p.image}}">
    <ion-card-header>
      <ion-card-subtitle>{{p.category}}</ion-card-subtitle>
      <ion-card-title>{{p.title}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      {{p.description}}
    </ion-card-content>
    <ion-button shape="round" fill="outline" (click)="onBtnClicked()">Read more</ion-button>
  </ion-card>
</ion-content>
